import React from 'react';
import { graphql, Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import cn from 'classnames';

import Layout from '@components/Layouts/Layout';
import { Header } from '@components/Blocks/Header';
import { TwoColumns } from '@components/Layouts/TwoColumns';
import { YandexTableau } from '@components/Layouts/YandexTableau';
import SEO from '@components/Layouts/SEO';

import pageStyles from '../page.module.scss';
import styles from './how-to.module.scss';

const imageComponent = (item, key) => (
  <GatsbyImage key={key} alt={item.title} image={item.image.childImageSharp.gatsbyImageData} />
);

export function Head() {
  return (
    <>
      <SEO
        title="Как собрать и заказать продукты"
        description="Заказать в Периодике очень легко."
      />
      <YandexTableau />
    </>
  );
}

const messageComponent = (item, key) => (
  <div className={styles.message} key={key}>
    <h2 className={styles.title}>{item.title}</h2>
    <div className={styles.description} dangerouslySetInnerHTML={{ __html: item.description }} />
    {item.btnTitle && item.btnAction && (
      <Link className={styles.btn} to={item.btnAction}>
        {item.btnTitle}
      </Link>
    )}
  </div>
);

function HowToPage({
  data: {
    allHowToJson: { nodes: items },
  },
}) {
  return (
    <Layout>
      <div
        className={cn({
          [pageStyles.header]: true,
          [styles.title]: true,
        })}
      >
        <Header>Как собрать и заказать продукты</Header>
      </div>
      <div className={pageStyles.content}>
        {items.map((item, index) => (
          <TwoColumns
            key={item.key}
            col1ClassName={index % 2 !== 0 ? styles.col1 : styles.col2}
            col2ClassName={index % 2 !== 0 ? styles.col2 : styles.col1}
            col1={
              index % 2 !== 0
                ? imageComponent(item, `${item.key}-${index}-left`)
                : messageComponent(item, `${item.key}-${index}-left`)
            }
            col2={
              index % 2 === 0
                ? imageComponent(item, `${item.key}-${index}-right`)
                : messageComponent(item, `${item.key}-${index}-right`)
            }
          />
        ))}
      </div>
    </Layout>
  );
}

export default HowToPage;

export const query = graphql`
  {
    allHowToJson {
      nodes {
        image {
          publicURL
          relativePath
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        title
        description
        btnTitle
        btnAction
        key
      }
    }
  }
`;
