import type { ReactNode } from 'react';
import React from 'react';
import cn from 'classnames';

import styles from './TwoColumns.module.scss';

interface TwoColumnsArgs {
  col1?: ReactNode;
  col2?: ReactNode;
  col1ClassName?: string;
  col2ClassName?: string;
}

export function TwoColumns({
  col1 = null,
  col2 = null,
  col1ClassName = undefined,
  col2ClassName = undefined,
}: TwoColumnsArgs) {
  return (
    <div className={styles.block}>
      <div
        className={cn({
          [styles.column]: true,
          ...(col1ClassName && { [col1ClassName]: true }),
        })}
      >
        {col1}
      </div>
      <div
        className={cn({
          [styles.column]: true,
          ...(col2ClassName && { [col2ClassName]: true }),
        })}
      >
        {col2}
      </div>
    </div>
  );
}
